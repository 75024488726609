
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "newSub-Step1",
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
    };
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  updated() {
    const store = useStore();
    
    // проврка
    store.dispatch(Actions.SAVE_DATA_SUB, {
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
      email: this.email,
      password: this.password,
    });
  },
});
