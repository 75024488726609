
import {defineComponent, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {ErrorMessage, Field} from "vee-validate";

export default defineComponent({
  name: "newSub-Step2",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const type = ref("personal");
    const getCurses = ref([]);

    const sendData = (id, paymentCost) => {
      store.dispatch(Actions.SAVE_DATA_SUB_OBJ, {
        type: type.value,
        id,
        paymentCost,
      });
    };

    onMounted(() => {
      setTimeout(() => {
        store
          .dispatch(Actions.CURSES_GET_ALL)
          .then(() => {
            let availableCourses = store.getters.getAllCurses.slice().filter(el => el.available === true);
            const data = {
              type: type.value,
              id: availableCourses[0]._id,
              paymentCost: availableCourses[0].payment_cost,
            };
            store.dispatch(Actions.SAVE_DATA_SUB_OBJ, data);
          })
          .then(() => {
            const data = store.getters.getAllCurses;
            getCurses.value = data.map((item) => {
              return {
                isAvailable: item.available,
                _id: item._id,
                course_name: item.course_name ? item.course_name : "-",
                course_description: item.course_description
                    ? item.course_description
                    : "-",
                course_icon: item.course_icon ? item.course_icon : "-",
                __v: item.__v ? item.__v : 0,
                payment_cost: item.payment_cost ? item.payment_cost : "-",
              };
            });
          });
      }, 250);
    });

    return {
      type,
      sendData,
      getCurses,
    };
  },
});
