
import { computed, defineComponent, onMounted, ref } from "vue";
import Step1 from "@/components/wizard/steps/newSubscriber/Step1.vue";
import Step2 from "@/components/wizard/steps/newSubscriber/Step2.vue";
import Step3 from "@/components/wizard/steps/newSubscriber/Step3.vue";
import { StepperComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { useForm } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

interface IStep1 {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

interface IStep2 {
  accountType: string;
}

interface CreateAccount extends IStep1, IStep2{}

export default defineComponent({
  name: "new-subscriber",
  components: {
    Step1,
    Step2,
    Step3,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const _stepperObj = ref<StepperComponent | null>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const submitButton = ref<HTMLElement | null>(null);

    const formData = ref<CreateAccount>({
      accountType: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        verticalWizardRef.value as HTMLElement
      );
      store.dispatch(Actions.CLEAR_DATA_SUB)

      setCurrentPageBreadcrumbs("Account", []);
    });

    const createAccountSchema = [
      Yup.object({
        firstName: Yup.string().required(t("isRequired")).label("First Name"),
        lastName: Yup.string().required(t("isRequired")).label("Last Name"),
        phoneNumber: Yup.string()
          .required()
          .matches(/^[0-9]+$/, t("onlyDigits"))
          .min(11, t("least11"))
          .max(11, t("most11"))
          .label("Phone"),
        email: Yup.string().min(4).required(t("isRequired")).email().label("Email"),
        password: Yup.string().min(4, t("passwordLeast4")).required(t("isRequired")).label("Password"),
      }),
      Yup.object({
        accountType: Yup.string().required().label("Type"),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<IStep1 | IStep2 >({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }
      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        const step1 = store.getters.getDataSubStep1
        const step2 = store.getters.getDataSubStep2

        const val = {
          email: step1.email,
          phone: step1.phoneNumber,
          password: step1.password,
          fname: step1.firstName,
          lname: step1.lastName,
          type: step2.type,
          course_id: step2.id,
          payment_cost: step2.paymentCost
        }

        store
          .dispatch(Actions.AUTH_SUB_REG, val)
          .then(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            // Go to page after successfully login
            window.location.href = store.getters.getPaymentUrl;
          })
          .catch(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: store.getters.getSubAuthError,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

      }, 500);
    };

    return {
      verticalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      submitButton,
      totalSteps,
      currentStepIndex,
      t,
    };
  },
});
